<template>
	<base-link class="article" to="article" :title="article.title" :params="{alias: article.alias}">
		<div class="article-main-info">
			<base-font class="article-title" weight="bold" variant="medium-heading" :tag="homepage ? 'h3' : 'h2'">{{article.title}}</base-font>
			<base-font class="article-read" variant="small">Tekst na <span class="important">{{article.timeToRead}} min</span></base-font>
			<base-font color="primary" variant="small">{{article.datePublished | date}}</base-font>
		</div>
		<div class="article-img-wrapper">
			<cms-image
				v-if="article.thumbnail"
				class="article-image"
                lazy
				:src="article.thumbnail"
				:base="{width: 500, height: 200}"
				:md="{width: 830, height: 200}"
				:xxl="{width: 1120, height: 200}"
			/>
			<base-button marginReset class="article-category">
				{{categoryName}}
			</base-button>
		</div>
		<div class="article-post-preview" v-html="article.introduction"></div>
		<base-button class="article-btn" type="secondary">
			{{langs.blog_button_text}}
		</base-button>
	</base-link>
</template>
<script>

export default {
	props: {
		article: [Object, Number],
		prefix: String,
		imageSize: {
			type: String,
			default: 'sm'
		}
	},
	computed: {
		langs () {
			return this.$app.translator.get('articles')
		},
		homepage () {
			const routeAlias = this.$app.page.route.meta.alias
			return routeAlias === 'homepage'
		},
		categoryName () {
			return this.article.category ? this.article.category.name : ''
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('articles')
	}
}
</script>
<style lang="scss" scoped>
	.article {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		text-transform: lowercase;
		&-main-info {
			margin-bottom: 2rem;
		}
		::v-deep &-title {
			margin-bottom: .5rem;
			margin-top: 0;
		}
		::v-deep &-read {
			display: block;
			/* text-transform: uppercase; */
			color: $gray;

			.important {
				color: $gray-500;
			}
		}
		&-post-preview {
			text-transform: initial;
			margin-top: 2rem;
			font-weight: 400;
			color: $primary;
		}
		&-btn {
			margin: 3rem 0;
		}
		&-category {
			padding: 1rem 3rem;
			border: 1px solid $white;
			background: rgba($white, .5);
			color: $primary;
			text-align: center;
			position: absolute;
			top: 1rem;
			right: 1rem;
			@include media-breakpoint-up(lg) {
				top: 2rem;
				right: 2rem;
				padding: 1.5rem 4rem;

			}
		}
		&-img-wrapper {
			position: relative;
			width: 100%;
            @include media-breakpoint-up(xl) {
                max-height: 200px;
                overflow: hidden;
            }

			::v-deep .base-image {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

		}

	}
</style>
