<template>
	<nav class="pagination">
		<div
			class="pagination-item"
			:class="{'pagination-item-disable': isDisableGoToPrevious}"
			@click="previousPage()"
		>
			<base-svg class="arrow arrow-left" name="arrow_down"/>
		</div>
		<div
			v-for="(item, i) in paginationsItems"
			class="pagination-item"
			:class="{
				'pagination-item-seperator': isNaN(item),
				'pagination-item-active': item === currentPage
			}"
			:key="isNaN(item) ? item + i : item"
			@click="goTo(item)"
		>
			{{ item }}
		</div>
		<div
			class="pagination-item"
			:class="{'pagination-item-disable': isDisableGoToNext}"
			@click="nextPage()"
		>
			<base-svg class="arrow arrow-right" name="arrow_down"/>
		</div>
	</nav>
</template>

<script>
export default {
	props: {
		pages: {
			type: Number,
			default: 1
		},
		currentPage: {
			type: Number,
			default: 1
		}
	},
	computed: {
		paginationsItems () {
			const uniqueArrayOfPages = [...new Set([
				1,
				this.currentPage - 1,
				this.currentPage,
				this.currentPage + 1,
				this.currentPage + 2,
				this.pages
			])].filter(page => page > 0 && page <= this.pages)

			const uniqueArrayOfPagesWithSeperators = uniqueArrayOfPages.reduce((result, value) => {
				if (result.length === 0) return [value]
				if (result[result.length - 1] + 1 !== value) result.push('...')
				result.push(value)
				return result
			}, [])

			return uniqueArrayOfPagesWithSeperators
		},
		isDisableGoToPrevious () {
			return this.currentPage === 1
		},
		isDisableGoToNext () {
			return this.currentPage === this.pages
		}
	},
	methods: {
		previousPage () {
			if (this.isDisableGoToPrevious) return
			this.$emit('page-change', this.currentPage - 1)
		},
		nextPage () {
			if (this.isDisableGoToNext) return
			this.$emit('page-change', this.currentPage + 1)
		},
		goTo (item) {
			if (isNaN(item)) return
			if (item === this.currentPage) return
			this.$emit('page-change', item)
		}
	}
}
</script>

<style lang="scss" scoped>

.pagination {
	display: flex;
	margin: 2rem 0 0 0;
	&-item {
		padding: 1rem 2rem;
		background: rgba($gray, .6);
		cursor: pointer;
		margin: 0 .5rem;
		font-weight: 500;
		transition: .3s ease-in;
		@include media-breakpoint-up(lg){
			padding: 2rem 3rem;
		}

		&:not(.pagination-item-active, .pagination-item-disable):hover{
			background: transparent;
		}
		&-disable {
			cursor: default;
			display: none;
		}
		&-seperator {
			cursor: default;
			background: $white;
		}
		&-active {
			cursor: default;
			background: $secondary;
			color: $white;
		}
		.arrow{
			height: 1rem;
			width: 1rem;

			&-right{
				transform: rotate(180deg);
			}
		}
	}
}
</style>
