<template>
	<div class="category-wrapper">
		<div class="inner-wrapper">
			<base-link v-for="category in categories" :title="`${category.name} - Blog | Oknar Kępno Sp. Z o.o.`" :key="category.id" type="blog" to="article-list-category" :params="{ category: category.alias }"  class="category">
				<p>{{category.name}}</p>
			</base-link>
            <base-link to="article-list" title="Powrót do wszystkich wpisów blogowych" type="blog" :params="{ alias: page.route.alias }" class="category">
				<p>{{langs.back_button}}</p>
			</base-link>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		categories: Array
	},
	computed: {
		page () {
			return this.$app.page
		},
		langs () {
			return this.$app.translator.get('articles')
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('articles')
	}
}

</script>
<style lang="scss" scoped>
body.safari {
    .category-wrapper {
        .inner-wrapper {
            gap: 0;
            .link {
                margin-bottom: 1rem;
            }
        }
    }
}
	.category-wrapper {
		width: 100%;
		height: 0;
		overflow: hidden;
		transition: .3s ease-in;
			@include media-breakpoint-up(lg){
				width: auto;
				height: auto;
				overflow: visible;
			}

		.inner-wrapper {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 1rem 0;
			gap: 1rem;
			@include media-breakpoint-up(lg){
				padding: 0;
			}
		}
		.category {
			padding: 1.5rem 2rem;
			border: none;
			outline: none;
			cursor: pointer;
			width: 100%;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: .2s ease-in;
			text-transform: uppercase;
			background: $secondary;
			color: $white;
			line-height: 1.8rem;
			@include transition;
			@include media-breakpoint-up(lg) {
				line-height: 2.3rem;
				text-align: center;
			}
			p {
				@include transition;
			}
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 4px;
				transition: .3s ease-in;
				transform-origin: bottom;
			}
			&::after {
				background: $secondary-500;
			}
			@include media-breakpoint-up(xl) {
				&:hover {
					p {
						transform: translateY(-3px);
					}
					&::after {
						transform: scaleY(1.8);
					}
				}
			}
		}
	}
</style>
