import { render, staticRenderFns } from "./BasePagination.vue?vue&type=template&id=2c694996&scoped=true"
import script from "./BasePagination.vue?vue&type=script&lang=js"
export * from "./BasePagination.vue?vue&type=script&lang=js"
import style0 from "./BasePagination.vue?vue&type=style&index=0&id=2c694996&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c694996",
  null
  
)

export default component.exports