<template>
	<li class="accordion-wrapper">
		<div
			v-if="$slots['accordion-trigger']"
			class="accordion-trigger accordion"
			:class="{'active': visible}"
			@click="open"
		>
			<button class="accordion-button" :class="{'open': visible}">
				<slot name="accordion-trigger"></slot>
			</button>
		</div>
		<transition
			name="accordion"
			@enter="start"
			@after-enter="end"
			@before-leave="start"
			@after-leave="end"
		>
			<div v-if="$slots['accordion-content']" class="accordion-content-wrapper" v-show="visible">
				<div class="accordion-content">
					<slot name="accordion-content"></slot>
				</div>
			</div>
		</transition>
	</li>
</template>

<script>
export default {
	props: {},
	inject: ['Accordion'],
	data () {
		return {
			index: null
		}
	},
	computed: {
		visible () {
			return this.index === this.Accordion.active
		}
	},
	methods: {
		open () {
			if (this.visible) {
				this.Accordion.active = null
			} else {
				this.Accordion.active = this.index
			}
		},
		start (el) {
			el.style.height = el.scrollHeight + 'px'
		},
		end (el) {
			el.style.height = ''
		}
	},
	created () {
		this.index = this.Accordion.count++
	}
}
</script>

<style lang="scss" scoped>

.accordion-enter-active,
.accordion-leave-active {
	will-change: height, opacity;
	transition: height 0.3s ease, opacity 0.3s ease;
	overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
	height: 0 !important;
	opacity: 0;
}

.accordion {
	&-wrapper {
		margin-bottom: 2rem;
	}
	&-button {
		padding: 1.5rem 2rem;
		border: none;
		outline: none;
		cursor: pointer;
		width: 100%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: .2s ease-in;
		text-transform: uppercase;
		background: $secondary;
		color: $white;
		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 4px;
			transition: .3s ease-in;
			transform-origin: bottom;
		}
		&::after {
			background: $secondary-500;
		}
		::v-deep p {
			line-height: 1.8rem;
			text-align: left;
			@include transition;
			@include media-breakpoint-up(lg) {
				line-height: 2.3rem;
				text-align: center;
			}
		}
		@include media-breakpoint-up(xl) {
			&:hover {
				&::after {
					transform: scaleY(1.8);
				}
				::v-deep p {
					transform: translateY(-3px);
				}
			}
		}
		::v-deep.svg-icon {
			flex-shrink: 0;
			position: relative;
			transition: .3s ease-in;
			pointer-events: none;
			margin-left: 2rem;
			height: 11px;
			width: 11px;
			path {
				stroke: $white;
			}

			@include media-breakpoint-up(lg) {
				margin-left: 0;
			}

		}
		&.open  ::v-deep.svg-icon{
			transform: rotate(180deg)
		}
	}
	&.active &-button {
		&::after {
			transform: scaleY(1.8);
		}
		::v-deep p {
			transform: translateY(-3px);
		}
	}

	&-content-wrapper {
		transition: .5s ease-in-out;
		overflow: hidden;
		background: $white;
	}
}
</style>
