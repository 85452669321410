<template>
	<section class="section">
		<base-container>
			<base-heading tag="h1" heading="main_title" subheading="main_subtitle"/>
			<div class="blog-wrapper">
				<div class="article-wrapper">
                <skeleton-loader class="blog-item" v-for="(article, i) in articles" :key="i" :isVisible="page.loading">
                    <blog-article :article="article"/>
                </skeleton-loader>
                </div>
                <div class="blog-aside">
					<div class="blog-categories">
						<base-button type="secondary" @click="toggleDropdown" marginReset class="categories-btn" :class="{ 'active' : activeDropdown}">{{langs.categories}}</base-button>
						<base-font  variant="small-heading" class="category-header">{{langs.categories}}</base-font>
						<article-categories ref="dropdown" :categories="page.articleCategories"/>
					</div>
					<div class="blog-popular">
						<base-font variant="small-heading" weight="bold" class="category-header">{{langs.popular_articles}}</base-font>
						<popular-articles :langs="langs"/>
					</div>
				</div>
			</div>
			<div class="product-list-navigation">
				<base-pagination :pages="page.numberOfPages" :currentPage="page.currentPage" @page-change="changePage"/>
			</div>
		</base-container>
	</section>
</template>
<script>
import BasePagination from '~/website/front/components/organisms/BasePagination'
import ArticleCategories from '~/blog/front/components/ArticleCategories/ArticleCategories.vue'
import PopularArticles from '~/blog/front/components/PopularArticles/PopularArticles.vue'
import BlogArticle from '../article/BlogArticle.vue'
import SkeletonLoader from '~/website/front/components/molecules/loaders/SkeletonLoader'
import BaseHeading from '~/website/front/components/molecules/headings/BaseHeading/BaseHeading.vue'
import { pageMixin } from '~/website/front/core/pages/page.mixin'
export default {
	components: {
		BasePagination,
		ArticleCategories,
		PopularArticles,
		BlogArticle,
		SkeletonLoader,
		BaseHeading
	},
	mixins: [pageMixin],
	data: () => ({
		activeDropdown: false
	}),
	computed: {
		articles () {
			return this.$app.page.articles
		},
		value () {
			return this.$app.page.value
		},
		page () {
			return this.$app.page
		},
		langs () {
			return this.$app.translator.get('articles')
		}
	},
	methods: {
		toggleDropdown () {
			this.activeDropdown = !this.activeDropdown
			const dropdownNode = this.$refs.dropdown.$el
			if (this.activeDropdown) dropdownNode.style.height = dropdownNode.scrollHeight + 'px'
			else dropdownNode.style.height = '0px'
		},
		changePage (page) {
			this.page.changePage(page)
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('articles')
	}
}
</script>
<style lang="scss" scoped>
body.safari section .container {
    .blog-wrapper {
        gap:0;
        .blog-aside {
            gap: 0;
            @include media-breakpoint-up(xl) {
                margin-left: 3rem;
            }
        }
    }
}
.section {
	min-height: 480px;
	padding: 10rem 0 4rem 0;
	@include media-breakpoint-up(xl) {
		padding: 16rem 0 8rem 0;
	}
}

.product-list-navigation {
	display: flex;
	justify-content: flex-end;
}
.blog-wrapper {
	display: flex;
	align-items: flex-start;
	flex-direction: column-reverse;
	gap: 3rem;
	width: 100%;
	@include media-breakpoint-up(lg) {
		flex-direction: row;
	}

	.blog-aside {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 3rem;
		@include media-breakpoint-up(lg) {
			position: sticky;
			top: 13rem;
		}
		@include media-breakpoint-up(xl) {
			max-width: 300px;
		}
		.categories-btn {
            width: 100%;
			padding-block: 1rem;
            font-size: 1.6rem;
            letter-spacing: 0.04em;
            padding: 1.5rem 2rem;
            border: none;
            outline: none;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1.8rem;
            transition: all 0.3s linear;
            font-weight: bold;
            margin-bottom: 2rem;
            &.active {
                margin-bottom: 0;
            }
			@include media-breakpoint-up(lg) {
				display: none;
			}
		}
		.blog-popular, ::v-deep .category-header {
			display: none;
			@include media-breakpoint-up(lg) {
				display: block;
			}
		}

		::v-deep .category-header {
			margin-bottom: 2rem;
			margin-top: 0;
		}
	}
}
</style>
