<template>
	<basic-accordion>
		<accordion-item v-for="(item, i) in mostReadPosts"  :key="i">
			<template v-if="item[1]" slot="accordion-trigger">
				<base-font size="sm" weight="bold" color="white" marginReset>{{langs[item[0]]}}</base-font>
			</template>
			<template slot="accordion-content">
				<div v-for="article in item[1]" :key="article.alias" class="article-element">
					<base-link type="primary" to="article" :title="article.title" :params="{alias: article.alias}">
						{{article.title}}
					</base-link>
				</div>
			</template>
		</accordion-item>
	</basic-accordion>
</template>
<script>
import BasicAccordion from '~/website/front/components/molecules/accordion/BasicAccordion'
import AccordionItem from '~/website/front/components/molecules/accordion/AccordionItem'
export default {
	components: {
		BasicAccordion,
		AccordionItem
	},
	async prefetch () {
		await this.$app.translator.prefetch('articles')
	},
	computed: {
		mostReadPosts () {
			return Object.entries(this.$app.page.mostRead)
		},
		langs () {
			return this.$app.translator.get('articles')
		}
	}
}
</script>
<style lang="scss" scoped>
    ::v-deep .article-element {
		margin-top: 2rem;
		text-transform: initial;
        /* background: $secondary;
        color: $white;
        margin: 1rem 0;
		text-transform: uppercase; */
        @include transition;
        /* &:hover{
            background: $white;
            .link{
                color: $secondary;
            }
        } */
    }
</style>
